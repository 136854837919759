import { HeartIcon } from '@heroicons/react/outline'
import React from 'react'
import { ProjectItem, Tech } from '../list-items/ProjectItem'

const ThingiverseProject = ({
  dateString,
  id,
  likes,
  title,
}: {
  dateString: string
  id: string
  likes: number
  title: string
}) => (
  <li>
    <div className='flex justify-between'>
      <a
        className='link print:text-slate-700 inline'
        href={`https://www.thingiverse.com/thing:${id}`}
        rel='noopener noreferrer'
        target='_blank'
      >
        <span>{title}&nbsp;</span>
        <span className='sm:mt-0 sm:inline-block text-content-light block mt-px'>
          ({likes}&nbsp;
          <HeartIcon className='fill-red-300 inline w-4 h-4 mr-px -mt-1 text-red-300' />
          )<span className='sm:hidden'>, {dateString}</span>
        </span>
      </a>
      <div className='sm:text-right sm:block shrink text-content-light hidden ml-4 text-xs'>
        {dateString}
      </div>
    </div>
  </li>
)

const thingiverse = [
  {
    dateString: 'July 28, 2020',
    id: '4553023',
    likes: 101,
    title: 'Rectangular IoT Bottle Lighting Pad',
  },
  {
    dateString: 'June 17, 2020',
    id: '4461313',
    likes: 206,
    title: 'IoT Bottle Lighting Pad',
  },
  {
    dateString: 'January 29, 2020',
    id: '4129249',
    likes: 1_367,
    title: 'Twisted WiFi Controlled Desk Lamp',
  },
  {
    dateString: 'June 16, 2019',
    id: '3676533',
    likes: 1_673,
    title: 'WiFi Controlled Desk Lamp',
  },
  {
    dateString: 'May 01, 2019',
    id: '3531086',
    likes: 127,
    title: 'Thingiverse - Animated RGB Logo',
  },
  {
    dateString: 'March 28, 2019',
    id: '3523487',
    likes: 228,
    title: 'Twenty One Pilots - Animated RGB Logo',
  },
  {
    dateString: 'January 15, 2019',
    id: '3354082',
    likes: 4_555,
    title: 'Smart Nanoleaf Replica',
  },
  {
    dateString: 'October 06, 2018',
    id: '3117494',
    likes: 336,
    title: 'Mini 7 Segment Smart Clock',
  },
  {
    dateString: 'June 23, 2018',
    id: '2968056',
    likes: 488,
    title: '7 Segment LED Smart Clock',
  },
]

const Projects = () => {
  return (
    <>
      <div className='pagebreak mt-12 ml-4 -mb-4 headline sm:hidden print:!block'>
        Projects
      </div>
      <ProjectItem
        additional={
          <Tech
            tech={[
              'AWS',
              'AWS CDK',
              'Typescript',
              'React/Vite.js',
              'TailwindCSS',
              'AWS Lambda',
              'AWS Cognito',
              'AWS DynamoDB',
              'AWS SES',
              'AWS S3',
              'AWS Cloudfront',
              'OpenAPI v3',
              'Orval',
              'AWS Opensearch',
              'AWS API-Gateway',
              'TanStack Query v4',
              'yarn workspaces',
              'AWS Step Functions',
              'Maizzle',
              'Stripe',
              'Mantine UI',
            ]}
          />
        }
        dateString='Jul 2022 - Present'
        description={
          <>
            {/* <p>
              A multi-tenant webshop and storage management system for
              organizing large corporation advertising material orders.
            </p>
            <p>
              Built as a highly scalable serverless application using AWS
              services.
            </p>
            <p className='mt-4'>
              It is scheduled to officially launch in December 2022.
            </p> */}
          </>
        }
        subtitle=''
        // subtitle='System for Distributing Advertising Materials'
        title='To be announced'
      />
      <ProjectItem
        additional={
          <Tech
            tech={[
              'AWS',
              'AWS API-Gateway',
              'AWS CDK',
              'Typescript',
              'React/Gatsby.js',
              'TailwindCSS',
              'AWS Lambda',
              'AWS Cognito',
              'AWS DynamoDB',
              'AWS SES',
              'AWS S3 & Cloudfront',
              'yup',
              'yarn',
            ]}
          />
        }
        dateString='Oct 2021 - Mar 2022'
        description={
          <>
            <p>
              An innovative web application that allows you to easily book golf
              tee times at over 35+ golf clubs.
            </p>
            <p>
              It includes a fully functional booking system as well as support
              for employee sub-accounts. Members receive an exclusive
              personalized member card that entitles them to benefits such as
              free training sessions with golf professionals.
            </p>
          </>
        }
        link={{
          href: 'https://bgl-prestige.com',
          text: 'Visit bgl-prestige.com',
        }}
        subtitle='A golf booking platform for organizations'
        title='BGL Prestige'
      />
      <ProjectItem
        additional={
          <div>
            <div className='flex flex-wrap mt-2 mb-0 text-xs'>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                Autodesk Fusion 350
              </div>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                C++
              </div>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                ESP8266
              </div>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                FDM 3D-Printing
              </div>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                MQTT
              </div>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                UDP
              </div>
              <div
                className='print:bg-white px-2 py-1 mt-2 mr-2'
                style={{ backgroundColor: '#eef1f5', color: '#546277' }}
              >
                WS2812B
              </div>
            </div>
          </div>
        }
        dateString='Jun 2018 - Jul 2020'
        description={
          <div className='space-y-3'>
            <p>
              Over the years, I've published a number of 3D-printing projects,
              with a particular emphasis on LED decoration using embedded
              addressable LED strips inside custom 3d-printed parts. I've also
              developed custom software for controlling the LEDs.
            </p>
            <ul className='mt-2'>
              <li>
                <div>
                  A forked webserver running on an esp8266 that controls the
                  LEDs
                </div>
                <ul className='mt-2'>
                  <li>A webserver with a responsive UI</li>
                  <li>Control brightness, colors and patterns</li>
                  <li>Over 30+ pre-built patterns</li>
                  <li>Support for Amazon Alexa</li>
                </ul>
                <a
                  className='link print:hidden block mb-4'
                  href='https://github.com/NimmLor/esp8266-fastled-iot-webserver'
                >
                  View on GitHub (347 ⭐)
                </a>
              </li>
              <li>
                A C# application that analyzes system audio and streams it to
                the microcontroller(s)
                <a
                  className='link print:hidden block'
                  href='https://github.com/NimmLor/IoT-Audio-Visualization-Center'
                >
                  View on GitHub (55 ⭐)
                </a>
              </li>
            </ul>
            <div className='pt-3 font-semibold'>
              Published projects on Thingiverse<span> (sorted by likes)</span>:
            </div>
            <ul className='md:text-base text-sm'>
              {thingiverse
                .sort((a, b) =>
                  new Date(a.dateString).getTime() >
                  new Date(b.dateString).getTime()
                    ? -1
                    : 1
                )
                .sort((a, b) => b.likes - a.likes)
                .map(item => (
                  <ThingiverseProject key={item.id} {...item} />
                ))}
            </ul>
            <div className='text-content-light text-xs text-right'>
              Stats from 06.03.2023
            </div>
          </div>
        }
        link={{
          href: 'https://www.thingiverse.com/surrbradl08/designs',
          text: 'View on Thingiverse',
        }}
        subtitle='Published under open-source licenses on thingiverse.com'
        title='3D-Printed LED Projects'
      />
    </>
  )
}

export default Projects
