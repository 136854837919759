import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from '../layout/Container'

const Skills = () => {
  return (
    <div className='pagebreak sm:py-12 py-10'>
      <Container disablePrintXMargin>
        <div className='mt-4 mb-6 headline sm:hidden print:!block'>Skills</div>
        <div className='space-y-14 w-full'>
          <div className='avoidbreak'>
            <div className='subheadline mb-4'>Amazon Web Services</div>
            <ul className='text-content mb-4'>
              <li>Comprehensive understanding of core services and concepts</li>
              <li>
                Familiar with best practices of the well-architected framework
              </li>
              <li>
                Extensive experience of serverless services and common design
                patterns
              </li>
              <li>Proficient in infrastructure automation with AWS CDK</li>
              <li>
                Hands on experience developing highly scalable applications
              </li>
              <li>Deep understanding of DynamoDB using single-table design</li>
            </ul>
            <div className='grid grid-cols-3 mt-8 text-sm text-center'>
              <a
                className='link print:text-left sm:text-sm text-xs'
                href='https://www.credly.com/badges/92d97be0-eb7b-4879-8c2c-6d9d9d1417de/public_url'
                rel='noopener noreferrer'
                target='_blank'
              >
                <StaticImage
                  alt='AWS Certified Solutions Architect Associate'
                  className='print:hidden sm:max-w-full max-w-[7.5rem]'
                  height={150}
                  loading='eager'
                  placeholder='blurred'
                  quality={80}
                  src='../../images/certs/saa.png'
                  width={150}
                />
                <div className='print:block text-slate-800 hidden mt-3 font-semibold'>
                  AWS Certified Solutions Architect - Associate
                </div>
                <div className='text-content mt-3 font-medium'>
                  Score: 962/1000
                </div>
                <div className='text-content mt-1 font-medium'>
                  18. August 2022
                </div>
              </a>
              <a
                className='link print:text-left sm:text-sm text-xs'
                href='https://www.credly.com/badges/1d0bbc1a-8d7e-4f11-bc2f-d105a2486634/public_url'
                rel='noreferrer'
                target='_blank'
              >
                <StaticImage
                  alt='AWS Certified Developer Associate'
                  className='print:hidden sm:max-w-full max-w-[7.5rem]'
                  height={150}
                  loading='eager'
                  placeholder='blurred'
                  quality={80}
                  src='../../images/certs/dva.png'
                  width={150}
                />
                <div className='print:block text-slate-800 hidden mt-3 font-semibold'>
                  AWS Certified Developer - Associate
                </div>
                <div className='text-content mt-3 font-medium'>
                  Score: 910/1000
                </div>
                <div className='text-content mt-1 font-medium'>
                  1. July 2022
                </div>
              </a>
              <a
                className='link print:text-left sm:text-sm text-xs'
                href='https://www.credly.com/badges/081fdb60-a1b6-4be6-9202-4828822ab84f/public_url'
                rel='noopener noreferrer'
              >
                <StaticImage
                  alt='AWS Certified Cloud Practitioner'
                  className='print:hidden sm:max-w-full max-w-[7.5rem]'
                  height={150}
                  loading='eager'
                  placeholder='blurred'
                  quality={80}
                  src='../../images/certs/clf.png'
                  width={150}
                />
                <div className='print:block text-slate-800 hidden mt-3 font-semibold'>
                  AWS Certified Cloud Practitioner
                </div>
                <div className='text-content mt-3 font-medium'>
                  Score: 865/1000
                </div>
                <div className='text-content mt-1 font-medium'>
                  27. April 2022
                </div>
              </a>
            </div>
          </div>
          <div className='avoidbreak'>
            <div className='subheadline mb-4'>Frontend Development</div>
            <ul className='text-content md:grid-cols-2 lg:grid-cols-3 grid mb-4'>
              <li>React: Vite, Gatsby</li>
              <li>Mantine</li>
              <li>Tanstack Query</li>
              <li>React-Native, Expo</li>
              <li>Chakra</li>
              <li>yup</li>
              <li>TailwindCSS</li>
              <li>Formik</li>
              <li>zod</li>
            </ul>
          </div>
          <div className='avoidbreak'>
            <div className='subheadline mb-4'>Backend Development</div>
            <ul className='text-content md:grid-cols-2 lg:grid-cols-3 grid mb-4'>
              <li>Node.js</li>
              <li>REST</li>
              <li>aws-lambda-powertools</li>
              <li>OpenAPI</li>
              <li>Stripe (Payments, Invoicing)</li>
              <li>tRPC</li>
              <li>Orval</li>
              <li>lambda-api</li>
              <li>zod</li>
            </ul>
          </div>
          <div className='avoidbreak'>
            <div className='subheadline mb-4'>Tooling</div>
            <ul className='text-content md:grid-cols-2 lg:grid-cols-3 grid mb-4'>
              <li>Yarn Workspaces</li>
              <li>ESLint</li>
              <li>GitHub Actions</li>
              <li>Vitest</li>
              <li>projen</li>
              <li>Prettier</li>
              <li>Maizzle</li>
              <li>Cypress</li>
              <li>Jest</li>
              <li>Docker</li>
              <li>Handlebars</li>
              <li>Turborepo</li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Skills
