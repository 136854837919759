import React from 'react'
import { Container } from '../layout/Container'

type ProjectItemProps = {
  additional?: React.ReactNode
  dateString: string
  description: React.ReactNode
  link?: {
    href: string
    text: string
  }
  subtitle: string
  title: string
}

export const Tech = ({ tech }: { tech: string[] }) => (
  <div className='flex flex-wrap mt-2 text-xs'>
    {tech
      .sort((a, b) => (a > b ? 1 : -1))
      .map(value => (
        <div
          className='px-2 py-1 mt-2 mr-2 print:bg-white'
          key={value}
          style={{ backgroundColor: '#eef1f5', color: '#546277' }}
        >
          {value}
        </div>
      ))}
  </div>
)

export const ProjectItem = ({
  dateString,
  description,
  link,
  title,
  subtitle,
  additional,
}: ProjectItemProps) => {
  return (
    <div className='py-10 border-b sm:py-12 border-slate-200 avoidbreak'>
      <Container disablePrintXMargin>
        <div className='flex flex-col items-start w-full'>
          <div className='flex w-full mt-2'>
            <div>
              <h2 className='font-bold md:text-lg text-slate-900'>{title}</h2>
            </div>
          </div>
          <div className='mb-4 text-base leading-5 tracking-wide text-content'>
            {subtitle}
          </div>
          <div className='order-first font-mono text-sm leading-7 text-content'>
            {dateString}
          </div>
          <p className='w-full mt-1 leading-7 md:leading-8 md:text-base text-content'>
            {description}
          </p>
          {link && (
            <a
              className='mt-4 mb-2 link'
              href={link.href}
              rel='noopener noreferrer'
              target='_blank'
            >
              {link.text}
            </a>
          )}
          {additional}
        </div>
      </Container>
    </div>
  )
}
