/* eslint-disable security/detect-possible-timing-attacks */
/* eslint-disable canonical/filename-match-exported */
import clsx from 'clsx'
import { useMemo } from 'react'
import React from 'react'
import { Layout } from '../components'
import Experience from '../components/content/Experience'
import Profile from '../components/content/Profile'
import Projects from '../components/content/Projects'
import Skills from '../components/content/Skills'
import Head from '../services/Head'

enum TAB {
  EXPERIENCE = 'experience',
  PROJECTS = 'projects',
  SKILLS = 'skills',
}

const IndexPage = () => {
  const currentTab = useMemo(
    () => {
      if (typeof window === 'undefined') return TAB.EXPERIENCE

      const hash = window.location.hash
      if (hash === '#projects') {
        return TAB.PROJECTS
      }

      if (hash === '#skills') {
        return TAB.SKILLS
      }

      return TAB.EXPERIENCE
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    typeof window === 'undefined' ? [] : [window.location.hash]
  )

  const setCurrentTab = (tab: TAB) => {
    if (typeof window !== 'undefined') window.location.hash = `#${tab}`
  }

  const tabs = [
    { label: 'Professional Experience', value: TAB.EXPERIENCE },
    { label: 'Skills', value: TAB.SKILLS },
    { label: 'Projects', value: TAB.PROJECTS },
  ]

  return (
    // <div className='snap-y snap-mandatory snap-always w-screen h-screen overflow-scroll'> // scroll version
    <div className='overflow-x-hidden'>
      <Head />
      {/* <Header /> */}
      {/* <Hero /> */}
      <Layout sidebarContent={<Profile />}>
        {/* <div className='headline mt-12 mb-10'>Professional Experience</div> */}
        <div className='lg:max-w-4xl mt-6'>
          {/* <div className='sm:hidden'>
            <label className='sr-only' htmlFor='tabs'>
              Select a tab
            </label>
            <select
              className='border-slate-300 block w-full rounded-md'
              defaultValue={currentTab}
              id='tabs'
              name='tabs'
            >
              {tabs.map(tab => (
                <option key={tab.value}>{tab.label}</option>
              ))}
            </select>
          </div> */}
          <div className='hidden w-full sm:block print:!hidden'>
            <div className='border-slate-200 border-b'>
              <nav
                aria-label='Tabs'
                className='flex -mb-px cursor-pointer select-none'
              >
                {tabs.map(tab => (
                  <div
                    aria-current={currentTab === tab.value ? 'page' : undefined}
                    className={clsx(
                      currentTab === tab.value
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300',
                      'w-1/2 py-4 px-1 text-center border-b-[3px] font-medium text-sm transition-colors'
                    )}
                    key={tab.value}
                    onClick={() => setCurrentTab(tab.value)}
                  >
                    {tab.label}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className='md:px-0 relative w-full max-w-4xl px-2'>
          <div
            className={`${
              currentTab === TAB.EXPERIENCE ? 'block' : 'sm:hidden'
            } top-0 transition-all duration-300 relative w-full fade-in print:!block print:!opacity-100`}
          >
            <Experience />
          </div>
          <div
            className={`${
              currentTab === TAB.SKILLS ? 'block' : 'sm:hidden'
            } top-0 transition-all duration-300 relative w-full fade-in print:!block print:!opacity-100`}
          >
            <Skills />
          </div>
          <div
            className={`${
              currentTab === TAB.PROJECTS ? 'block' : 'sm:hidden'
            } top-0 transition-all duration-300 relative w-full fade-in print:!block print:!opacity-100`}
          >
            <Projects />
          </div>
        </div>
      </Layout>

      {/* <Services /> */}
    </div>
  )
}

export default IndexPage
