import React from 'react'
import { EmploymentItem } from '../list-items/EmploymentItem'

const Experience = () => {
  return (
    <>
      <div className='pagebreak mt-12 ml-4 -mb-4 headline sm:hidden print:!block'>
        Professional Experience
      </div>
      <EmploymentItem
        company='GrowthSquare GmbH'
        dateString='Jun. 2023 - Present'
        description={
          <div>
            <p>
              I'm playing a leading role in developing user-friendly software
              for business planning, performance, and leadership.
            </p>
            <p className='mt-4 mb-4'>
              Key responsibilities and achievements include:
            </p>
            <ul>
              <li>
                Engineered multi-tenant SaaS platform for enterprise strategy,
                performance, and leadership.
              </li>
              <li>
                Established efficient Monorepo and selected optimal tech stack.
              </li>
              <li>
                Implemented CI/CD with GitHub Actions for seamless deployment.
              </li>
              <li>Integrated SSO with AWS Cognito for enhanced security.</li>
              <li>Conducted rigorous testing using Vitest and Cypress.</li>
              <li>Leveraged CloudWatch and X-Ray for robust tracing.</li>
            </ul>
          </div>
        }
        employmentType='Full-time'
        role='Head of Cloud Operations'
      />
      <EmploymentItem
        company='hae.sh GmbH'
        dateString='Sept. 2021 - May 2023'
        description={
          <div>
            <p>
              At hae.sh, I've worked as a Cloud Engineer on a SaaS product that
              provides bulletproof assurance for internal control systems. I was
              in charge of designing and implementing a scalable and efficient
              cloud-based solution.
            </p>
            <p className='mt-4 mb-4'>Some of my key contributions included:</p>
            <ul>
              <li>
                Developing and maintaining AWS Infrastructure as Code with AWS
                CDK, ensuring consistent deployment of resources across
                different environments.
              </li>
              <li>
                Setting up an infrastructure CI/CD system using CDK Pipelines,
                enabling automated deployments and ensuring consistent and
                reliable infrastructure changes.
              </li>
              <li>
                Building GitHub Actions for Pull Requests that create an
                emphemeral AWS environment used to run integration tests
                against.
              </li>
              <li>
                Architected the core data stream processing and control
                execution systems, ensuring optimal performance and scalability.
              </li>
              <li>
                Implemented multi-tenancy and authorization with support for
                SSO, ensuring a seamless user experience.
              </li>
              <li>
                Designed and modeled DynamoDB tables with Single-Table-Design,
                optimizing for query performance and minimizing costs.
              </li>
              <li>
                Developed AWS Lambda-based microservices and API endpoints in
                Typescript
              </li>
            </ul>
          </div>
        }
        employmentType='Full-time'
        role='Cloud Engineer'
      />
      <EmploymentItem
        company='LUCI GmbH'
        dateString='Jan. 2021 - Aug. 2021'
        description={
          <ul>
            <li>
              Worked on LUCI. A SaaS communication platform for nursing homes.
            </li>
            <li>
              It includes a React-Native mobile app, a React admin dashboard,
              and a React tenant management tool.
            </li>
            <li>
              Supported by a multi-tenancy backend built on AWS using
              Infrastructure as Code and AWS CDK. Based on AWS Lambda, DynamoDB,
              and AWS Cognito.
            </li>
            <li>
              Created the entire MVP, briefed the newly hired team on the
              architecture and the implementation.
            </li>
            <li className='unstyled'>
              <a
                className='link mt-4 mb-2'
                href='https://luciapp.de/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Visit luciapp.de
              </a>
            </li>
          </ul>
        }
        employmentType='Freelance'
        role='Fullstack Developer'
      />
      <EmploymentItem
        dateString='Jul. 2019 - Present'
        description={
          <ul>
            <li>Development of various cloud applications on AWS</li>
            <li>
              <div className='mb-1.5'>Conducted projects</div>
              <ul>
                <li>
                  <a
                    className='link print:font-normal font-medium'
                    href='https://bgl-prestige.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    BGL-Prestige
                  </a>
                  : Golf booking platform for organizations
                </li>
                <li>
                  <a
                    className='link print:font-normal font-medium'
                    href='https://golfwm.at'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Business Golf League
                  </a>
                  : Event website for company golf events
                </li>
                <li>
                  <a
                    className='link print:font-normal font-medium'
                    href='https://play.google.com/store/apps/details?id=com.vispecs.app'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    ViSpecs
                  </a>
                  : An app for the optics industry
                </li>
              </ul>
            </li>
          </ul>
        }
        role='Freelancer'
      />
    </>
  )
}

export default Experience
